<template>
  <div class="home">
    <el-container>
      <el-header
        v-if="offsetwidth > 1005"
        style="background-color: rgb(41, 49, 60); width: 100%"
      >
        <el-menu
          router
          :default-active="activeIndex"
          background-color="#283144"
          text-color="#fff"
          active-text-color="#41a264"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect"
          style="margin-left: 360px"
        >
          <div style="float: left">
            <img
              style="height: 56px"
              src="../assets/img/blacklogo.jpg"
              alt=""
            />
          </div>
          <el-menu-item style="margin-left: 2%" index="/index"
            >Home</el-menu-item
          >
          <el-menu-item style="margin-left: 2%" index="/AboutUs"
            >About Us</el-menu-item
          >
           <el-submenu
          index="products"
          style="margin-left: 2%;text-align:left"
          >
            <template slot="title">Our Products</template>
            <!-- <el-menu-item index="/HomeEssential"
              >Home Essential</el-menu-item
            > -->
            <el-menu-item index="/SolarPanelSystem" 
              >Solar Panel System</el-menu-item
            >
            <el-menu-item index="/MAC"
              >Air Conditioning</el-menu-item
            >
          </el-submenu>
          <!-- <el-menu-item style="margin-left: 2%" index="/Products"
            >Products</el-menu-item
          > -->
          <el-menu-item style="margin-left: 2%" index="/gallery"
            >Gallery</el-menu-item
          >
          <el-menu-item style="margin-left: 2%" index="/ContactUs"
            >Contact Us</el-menu-item
          >
          <el-menu-item style="margin-left: 2%" index="/CustomerDetails"
            >Join Now</el-menu-item
          >
          <el-menu-item style="margin-left: 2%" index="/privacyPolicy"
            >Privacy Policy</el-menu-item
          >
          <el-menu-item style="margin-left: 2%" index="/terms"
            >Terms and conditions</el-menu-item
          >
        </el-menu>
      </el-header>

      <el-header v-else style="background-color: rgb(41, 49, 60); width: 100%">
        <el-menu
          router
          :default-active="activeIndex"
          background-color="#283144"
          text-color="#fff"
          active-text-color="#41a264"
          class="el-menu-demo"
          @select="handleSelect"
        >
          <div style="float: left">
            <img
              style="height: 56px; margin-left: 10px"
              src="../assets/img/phone/blacklogo.jpg"
              alt=""
            />
          </div>
          <div
            @click="showmenu = !showmenu"
            style="background-color: #fff; width: 50px;padding:5px;0;border-radius:5px;position:absolute;top:10px;right:10px;"
          >
            <img src="../assets/img/menu.png" style="width: 20px" />
          </div>
          <el-menu-item v-show="showmenu" index="/index">Home</el-menu-item>
          <el-menu-item v-show="showmenu" index="/AboutUs"
            >About Us</el-menu-item
          >
            <el-submenu
            index="Productsh5"
             v-show="showmenu" 
                 style="text-align:left"
          >
            <template slot="title" >Our Products</template>
           <!-- <el-menu-item index="/HomeEssential"
              >Home Essential</el-menu-item
            > -->
            <el-menu-item index="/SolarPanelSystem" 
              >Solar Panel System</el-menu-item
            >
            <el-menu-item index="/MAC"
              >Air Conditioning</el-menu-item
            ></el-submenu>
          <!-- <el-menu-item v-show="showmenu" index="/Products"
            >Products</el-menu-item
          > -->
          <el-menu-item v-show="showmenu" index="/gallery"
            >Gallery</el-menu-item
          >
          <el-menu-item v-show="showmenu" index="/ContactUs"
            >Contact Us</el-menu-item
          >
          <el-menu-item v-show="showmenu" index="/CustomerDetails"
            >Join Now</el-menu-item
          >
          <el-menu-item v-show="showmenu" index="/privacyPolicy"
            >Privacy Policy</el-menu-item
          >
           <el-menu-item v-show="showmenu" index="/terms"
            >Terms and conditions</el-menu-item
          >
        </el-menu>
      </el-header>
      <!-- <el-main> -->
      <div style="width: 100%" class="flex_around">
        <router-view
          style="width: 100%"
          @fun="getinfo"
          :offsetwidth="offsetwidth"
        ></router-view>
      </div>
      <!-- </el-main> -->
      <!-- <el-footer>Footer</el-footer> -->
    </el-container>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: "Home",
  components: {
    // HelloWorld
  },
  data() {
    return {
      showmenu: false,
      activeIndex: "/index",
      offsetwidth: document.documentElement.clientWidth,
    };
  },
  // computed: {
  //   activeIndex() {
  //     return window.sessionStorage.getItem("activeIndex");
  //   },
  // },
  watch: {
    // 如果 `offset ` 发生改变，这个函数就会运行
    offsetwidth: function (val) {
      if (!this.timer) {
        this.offsetwidth = val;
        // this.showmenu = this.offsetwidth > 700;
        // this.changeheight(val);
        this.timer = true;
        let that = this;
        let timeout = setTimeout(function () {
          // clearTimeout(timeout);/
          //频繁触发 resize 函数，会导致页面很卡
          that.timer = false;
        }, 100);
      }
    },
  },
  mounted() {
    window.sessionStorage.getItem("activeIndex")
      ? (this.activeIndex = window.sessionStorage.getItem("activeIndex"))
      : "";
    let that = this;
    window.addEventListener("resize", () => {
      window.offsetwidth = document.documentElement.clientWidth;
      that.offsetwidth = window.offsetwidth;
    });
    // router.beforeEach((to, from, next) => {});
  },
  methods: {
    getinfo(data) {
      this.activeIndex = data;
      window.sessionStorage.setItem("activeIndex", "/CustomerDetails");

      this.$router.push("/CustomerDetails");
    },
    handleSelect(key, keyPath) {
      window.sessionStorage.setItem("activeIndex", key);
      this.activeIndex = key;
      this.showmenu = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.el-header {
  z-index: 10;
  ul {
    margin-left: 0 !important;
    display: contents;
  }
}
.flex_around {
  display: flex;
  justify-content: space-around;
}
@media screen and (max-width: 920px) {
  .el-menu {
    z-index: 99;
    list-style: none;
    position: relative;
    margin: 0;
    padding-left: 0;
    background-color: #283144;
    justify-content: space-around;
  }
  .el-menu-item {
    font-size: 14px;
    text-align: left;
    color: #303133;
    padding: 0 12px;
    cursor: pointer;
    transition: border-color 0.3s, background-color 0.3s, color 0.3s;
    box-sizing: border-box;
  }
}
.el-menu-item:hover {
  color: #85c442 !important;
  border-bottom-color: #85c442 !important;
}
@media screen and (min-width: 919px) {
  .el-menu {
    border-right: solid 1px #e6e6e6;
    list-style: none;
    position: relative;
    margin: 0;
    padding-left: 0;
    background-color: #fff;
  }
}
.el-button {
  color: #fff;
  background-color: #545c64;
  border: 1px solid #fff;
  font-weight: 700;
}
.content {
  height: calc(100vh - 60px);
}
.el-menu.el-menu--horizontal {
  border-bottom: none;
}
</style>
